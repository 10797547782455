.header {
  display: flex;
  justify-content: center;
}
.nav_logo {
  width: 15rem;
  height: 3rem;
  border-radius: 50px;
  margin: -1rem 1rem;
}
.header_menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}

.header_menu > li:hover {
  cursor: pointer;
  color: var(--orange);
}
@media screen  and (max-width:768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header_menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
    .nav_logo {
        margin-bottom: 1rem;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}