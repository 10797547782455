.footer-container {
  position: relative;
  display: flex;
}
.footer-container > hr {
  border: 1px solid lightgray;
}
.footer {
  padding: 1rem 2rem;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  /* height: 20rem; */
}
.social-link {
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: center;
}
.social-link > .icons {
  height: 2rem;
  width: 2rem;
  color: #fff;
  cursor: pointer;
}
/* .logo-f{
    width: 10rem;
} */

.blur-f-1{
  bottom: 0;
  right: 15%;
  width: 26rem;
  height: 12rem;filter: blur(200px);
  background-color: red;
}
.blur-f-2{
  bottom: 0;
  left: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background-color: rgb(255, 115, 0);
}

.left-f {
  flex: 1 1;
}
.right-f {
  flex: 1 1;
}

.deatils-f {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deatils-f > :nth-child(1) {
  font-size: 3rem;
  text-transform: uppercase;
}
.copy-right{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
  letter-spacing: 2px;
}

@media screen  and (max-width:768px){
  .footer-container{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .deatils-f>:nth-child(2){
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: small;
  }
}

