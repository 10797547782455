.plans-containers {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}
.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: #fff;
  gap: 1.5rem;
  padding: 1rem;
}
.plan:nth-child(2) {
  background: var(--planCard);
  transform: scale(1.1);
}
.plan > .img {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}

.plan > span:nth-of-type(1) {
  font-weight: bold;
  color: rgb(43, 226, 113);
}
.plan > span:nth-of-type(2) {
  font-size: 2rem;
  font-weight: bold;
}
.plan > div:nth-child(5) {
  color: aqua;
  font-size: 0.8rem;
}
.plan > div:nth-child(4) {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.plans-blur-1{
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}
.plans-blur-2{
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0;
}
@media screen and (max-width: 768px){
  .plans{
    flex-direction: column;
  }
}

